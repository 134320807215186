import * as React from "react";
import { Alert, Card, CardContent, Skeleton, Typography } from "@mui/material";
import { useTasksNew, useTaskStatuses } from "../hooks";
import { Task } from "../types/types";
import { TaskSection } from "./TaskSection";

interface TaskListTileProps {
  ccid: string | undefined;
}

export function TaskListTile({ ccid }: TaskListTileProps) {
  const { data: taskData, isLoading } = useTasksNew(ccid);

  if (isLoading) {
    return (
      <TaskListTileContainer>
        <Skeleton variant="rounded" animation="wave" height={800} />
      </TaskListTileContainer>
    );
  }

  if (!taskData) {
    return (
      <TaskListTileContainer>
        <Alert severity="error">
          There was an error retrieving the tasks for this production
        </Alert>
      </TaskListTileContainer>
    );
  }

  return (
    <TaskListTileContainer>
      <TaskSections tasks={taskData.tasks} />
    </TaskListTileContainer>
  );
}

function TaskListTileContainer({ children }: { children: React.ReactNode }) {
  return (
    <Card>
      <CardContent>
        <Typography variant="h5" paddingBottom="16px">
          Production Tasks
        </Typography>

        {children}
      </CardContent>
    </Card>
  );
}

function TaskSections({ tasks }: { tasks: Array<Task> }) {
  const statusUrls: Array<string> = tasks
    .filter((task) => Boolean(task.taskStatusUrl))
    .map((task) => task.taskStatusUrl as string);

  const statuses = useTaskStatuses({ statusUrls });

  const isLoadingStatuses = statuses.some((statusData) => statusData.isLoading);

  if (isLoadingStatuses) {
    return <Skeleton variant="rounded" animation="wave" height={800} />;
  }

  return (
    <>
      <TaskSection tasks={tasks} productionStage="preProduction" />
      <TaskSection tasks={tasks} productionStage="inProduction" />
      <TaskSection tasks={tasks} productionStage="postProduction" />
    </>
  );
}
