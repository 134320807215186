/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosInstance } from "axios";
import { getAPI, SERVICES } from "../lib/getApi";
import handleRequest from "../lib/handleRequest";
import axiosRetry from "axios-retry";
import {
  CloseDownForm,
  ProductionDetails,
  ProductionDetailsWithBasicInfo,
  StartUpForm,
} from "../types/types";

const API_BASE = getAPI(SERVICES.storyboard);
const client: AxiosInstance = axios.create();
axiosRetry(client, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
});

export const getStartupFormData = ({
  ccid,
  type,
}: {
  ccid: string;
  type: "series" | "titles";
}) =>
  handleRequest
    .get(`${API_BASE}/${type}/${ccid}/startup`)
    .then(({ data }) => data as StartUpForm);

export const updateStartupFormData = ({
  ccid,
  formData,
  type,
}: {
  ccid: string;
  type: "series" | "titles";
  formData: StartUpForm;
}): Promise<""> =>
  handleRequest
    .put(`${API_BASE}/${type}/${ccid}/startup`, formData, undefined, client)
    .then(({ data }) => data as "");

export const getStartupFormProductionData = (
  ccid: string,
): Promise<ProductionDetailsWithBasicInfo> =>
  handleRequest
    .get(`${API_BASE}/ccid/${ccid}/productionDetails`)
    .then(({ data }) => data as ProductionDetailsWithBasicInfo);

export const updateStartupFormProductionData = ({
  ccid,
  formData,
}: {
  ccid: string;
  formData: ProductionDetails;
}): Promise<""> =>
  handleRequest
    .put(
      `${API_BASE}/ccid/${ccid}/productionDetails`,
      formData,
      undefined,
      client,
    )
    .then(({ data }) => data as "");

export const getCloseDownFormData = ({
  ccid,
  type,
}: {
  ccid: string;
  type: "series" | "titles";
}): Promise<CloseDownForm> =>
  handleRequest
    .get(`${API_BASE}/${type}/${ccid}/closedown`)
    .then(({ data }) => data as CloseDownForm);

export const updateCloseDownFormData = ({
  ccid,
  formData,
  type,
}: {
  ccid: string;
  formData: CloseDownForm;
  type: "series" | "titles";
}): Promise<""> =>
  handleRequest
    .put(`${API_BASE}/${type}/${ccid}/closedown`, formData)
    .then(({ data }) => data as "");
