/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery, UseQueryResult } from "react-query";
import { AxiosError } from "axios";
import { getStartupFormData } from "../../api/storyboard";
import { StartUpForm } from "../../types/types";

export const useStartup = ({
  ccid,
  type,
}: {
  ccid: string | undefined;
  type: "series" | "titles";
}): UseQueryResult<StartUpForm, AxiosError> =>
  useQuery({
    queryKey: ["startup", type, ccid],
    queryFn: () => getStartupFormData({ ccid: ccid as string, type }),
    enabled: Boolean(ccid),
  });
