import { useQueries } from "react-query";
import handleRequest from "../../lib/handleRequest";
import { TaskStatusPayload } from "../../types/types";
import { TaskStatusQueryKey } from "./useTaskStatus";

interface UseTaskStatusesArgs {
  statusUrls: Array<string>;
}

export function useTaskStatuses({ statusUrls }: UseTaskStatusesArgs) {
  return useQueries(
    statusUrls.map((url) => {
      const queryKey: TaskStatusQueryKey = ["task status", url];
      return {
        queryKey,
        queryFn: async () => {
          const { data } = await handleRequest.get(url);
          return data as TaskStatusPayload;
        },
        retry: false,
        retryOnMount: false,
      };
    }),
  );
}
