import { useMutation } from "react-query";
import { updateCloseDownFormData } from "../../api/storyboard";
import { CloseDownForm } from "../../types/types";
import { noop } from "../../utils/appHelper";

export const useUpdateCloseDown = (onSuccess = noop, onError = noop) =>
  useMutation(
    ({
      ccid,
      formData,
      type,
    }: {
      ccid: string;
      formData: CloseDownForm;
      type: "series" | "titles";
    }) => updateCloseDownFormData({ ccid, formData, type }),
    { onSuccess, onError },
  );
