import * as Yup from "yup";

export const locationsAndTravelSchemaV3 = Yup.object().shape({
  spotId: Yup.string(),
  countries: Yup.array()
    .of(Yup.string().required())
    .required("This is a required field")
    .min(1, "This is a required field"),
  operatingEnvironment: Yup.array()
    .of(Yup.string().required())
    .required("This is a required field")
    .min(1, "This is a required field"),
  travel: Yup.boolean().required("This is a required field"),
  travelBooking: Yup.boolean().required("This is a required field"),
  environment: Yup.boolean().required("This is a required field"),
  cultural: Yup.boolean().required("This is a required field"),
  disruption: Yup.boolean().required("This is a required field"),
});

export type LocationsAndTravelSectionV3 = Yup.InferType<
  typeof locationsAndTravelSchemaV3
>;
