import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Skeleton,
  Typography,
} from "@mui/material";
import { useGetTreatments } from "../hooks";
import { LevelType, Treatment } from "../types/types";
import handleRequest from "../lib/handleRequest";
import { enqueueSnackbar } from "notistack";
import { SnackbarDismiss } from "../componentsV2/SnackBarDismiss";
import { getAltThumbnail } from "../containers/Synopsis/Edit/Images/Thumbnail/Thumbnail";
import { useMutation } from "react-query";
import theme from "../utils/theme";
import { formatBytes } from "react-dropzone-uploader";
import { Download } from "@mui/icons-material";
import { useState } from "react";

interface TreatmentTileProps {
  ccid: string | undefined;
  level: LevelType;
}

export function TreatmentTile({ ccid, level }: TreatmentTileProps) {
  const title = level === "series" ? "Series Treatments" : "Treatments";

  return (
    <Card>
      <CardContent>
        <Typography variant="h5">{title}</Typography>
        <TreatmentTileContent ccid={ccid} />
      </CardContent>
    </Card>
  );
}

function TreatmentTileContent({ ccid }: { ccid: string | undefined }) {
  const { data: treatment, isLoading, error } = useGetTreatments(ccid);

  if (isLoading) {
    return (
      <Box paddingY={2}>
        <Skeleton
          height="150px"
          width="100%"
          variant="rounded"
          animation="wave"
        />
      </Box>
    );
  }

  if (error && error?.response?.status !== 404) {
    return (
      <Box paddingY={2}>
        <Alert severity="error">
          There was an issue retrieving the treatment assets for this production
        </Alert>
      </Box>
    );
  }

  if (!treatment || !treatment.assets.length) {
    return (
      <Box paddingY={2}>
        <Alert severity="info">
          No treatment assets found for this production
        </Alert>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        overflowX: "auto",
        gap: 2,
        paddingTop: 2,
        whiteSpace: "nowrap",
      }}
    >
      {treatment.assets.map((asset, index) => (
        <TreatmentAsset key={asset.url} asset={asset} assetIndex={index} />
      ))}
    </Box>
  );
}

function TreatmentAsset({
  asset,
  assetIndex,
}: {
  asset: Treatment;
  assetIndex: number;
}) {
  const [assetWidth, setAssetWidth] = useState(0);

  const { mutate: downloadAsset, isLoading } = useMutation({
    mutationFn: async () => {
      // Use Blob method to download when no thumbnails are available
      const response = await handleRequest
        .get(asset.url, {
          responseType: "blob",
          headers: {},
        })
        .then((res) => new Blob([res.data], { type: `${asset.mimeType}` }));
      const url = window.URL.createObjectURL(response);

      // Create an anchor element and trigger the download
      const a = document.createElement("a");
      a.href = url;
      a.download = asset.filename;
      a.click();

      // Revoke the Blob URL after download
      window.URL.revokeObjectURL(url);
    },

    onError: () => {
      enqueueSnackbar(
        "Failed to download asset. Please try again or contact us if the issue persists",
        { variant: "error", action: SnackbarDismiss },
      );
    },
  });

  return (
    <Box>
      <TreatmentAssetContainer
        asset={asset}
        downloadAsset={downloadAsset}
        assetWidth={assetWidth}
        isLoading={isLoading}
      >
        <Box
          component="img"
          src={
            asset.thumbnails
              ? asset.thumbnails.large.url
              : getAltThumbnail(asset.filename)
          }
          alt={`Asset ${assetIndex + 1}`}
          height="180px"
          onLoad={(event) => {
            setAssetWidth(event.currentTarget.getBoundingClientRect().width);
          }}
          border={`2px solid ${asset.thumbnails ? "transparent" : theme.palette.text.disabled}`}
        />
      </TreatmentAssetContainer>
    </Box>
  );
}

function TreatmentAssetContainer({
  asset,
  children,
  downloadAsset,
  isLoading,
  assetWidth,
}: {
  asset: Treatment;
  children: React.ReactNode;
  downloadAsset: () => void;
  isLoading: boolean;
  assetWidth: number;
}) {
  const overlay = (
    <Box
      className="asset-overlay"
      sx={{
        opacity: 0,
        transition: "opacity 500ms ease-in-out",
      }}
      position="absolute"
      width="100%"
      height="100%"
      bottom={0}
      bgcolor="rgba(0, 0, 0, 0.8)"
      display="flex"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      padding={1}
      color="#fff"
    >
      <Download color="inherit" sx={{ fontSize: "48px" }} />
    </Box>
  );

  const caption = (
    <Box paddingY="8px">
      <Typography variant="body2">{asset.filename}</Typography>
      <Typography variant="caption">{formatBytes(asset.size)}</Typography>
    </Box>
  );

  if (isLoading) {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "180px",
            width: assetWidth,
          }}
        >
          <CircularProgress size={32} />
        </Box>
        {caption}
      </>
    );
  }

  if (asset.thumbnails) {
    return (
      <>
        <Box
          component="a"
          href={asset.url}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={`download ${asset.filename}`}
          sx={{
            display: "inline-block",
            position: "relative",
            wordBreak: "break-all",
            padding: 0,
            "&:hover, &:focus": {
              "& .asset-overlay": { opacity: 1 },
            },
          }}
        >
          {overlay}
          {children}
        </Box>

        {caption}
      </>
    );
  }

  return (
    <>
      <Button
        aria-label={`download ${asset.filename}`}
        onClick={downloadAsset}
        disableRipple={true}
        sx={{
          position: "relative",
          wordBreak: "break-all",
          padding: 0,
          "&:hover, &:focus": {
            "& .asset-overlay": { opacity: 1 },
          },
        }}
      >
        {overlay}
        {children}
      </Button>

      {caption}
    </>
  );
}
