import {
  Grid,
  Typography,
  Card,
  CardContent,
  Box,
  Alert,
  Skeleton,
} from "@mui/material";
import { Task, TaskStatusPayload } from "../types/types";
import {
  useGetAssignedTasks,
  useTaskStatuses,
  TaskStatusQueryKey,
  useUserFullName,
} from "../hooks";
import { useParams } from "react-router-dom";
import {
  preProductionTasks,
  postProductionTasks,
  inProductionTasks,
} from "./TasksList";
import AssignedTaskItem from "./AssignedTaskItem";
import { useQueryClient } from "react-query";

const allTaskList = {
  ...preProductionTasks,
  ...inProductionTasks,
  ...postProductionTasks,
};

const AssignedTasksTile = (): JSX.Element => {
  const { seriesCcid, titleCcid } = useParams<string>();
  const levelCcid = titleCcid || seriesCcid;

  const { data: assigneeData, isLoading: loadingAssigneeData } =
    useGetAssignedTasks(levelCcid as string);

  if (loadingAssigneeData) {
    return (
      <AssignedTasksTileContainer>
        <Skeleton variant="rounded" animation="wave" height={200} />
      </AssignedTasksTileContainer>
    );
  }

  if (!assigneeData) {
    return (
      <AssignedTasksTileContainer>
        <Alert severity="error">
          There was an error retrieving your assigned tasks for this production
        </Alert>
      </AssignedTasksTileContainer>
    );
  }

  if (assigneeData.tasks.length === 0) {
    return (
      <AssignedTasksTileContainer>
        <Alert severity="info">You currently have no assigned tasks</Alert>
      </AssignedTasksTileContainer>
    );
  }

  return (
    <AssignedTasksTileContainer>
      <AssignedTasksList assignedTasks={assigneeData.tasks} />
    </AssignedTasksTileContainer>
  );
};

function AssignedTasksTileContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  const fullName = useUserFullName();

  const titleContent = () => {
    if (fullName) {
      return (
        <>
          Assigned Tasks for{" "}
          <Box component="span" textTransform="capitalize">
            {fullName}
          </Box>
        </>
      );
    }

    return "Assigned Tasks";
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h5">{titleContent()}</Typography>
        <Typography variant="body2" paddingBottom="24px">
          These tasks have been assigned to you to complete
        </Typography>

        {children}
      </CardContent>
    </Card>
  );
}

type TaskWithStatus = Task & {
  completed: boolean;
};

function AssignedTasksList({ assignedTasks }: { assignedTasks: Array<Task> }) {
  const queryClient = useQueryClient();
  const statusUrls: Array<string> = assignedTasks
    .filter((task) => Boolean(task.taskStatusUrl))
    .map((task) => task.taskStatusUrl as string);

  const statuses = useTaskStatuses({ statusUrls });

  const isLoadingStatuses = statuses.some((statusData) => statusData.isLoading);

  if (isLoadingStatuses) {
    return <Skeleton variant="rounded" animation="wave" height={200} />;
  }

  const tasksWithStatus: Array<TaskWithStatus> = assignedTasks.map((task) => {
    let isCompleted = false;

    if (task.taskStatusUrl) {
      const queryKey: TaskStatusQueryKey = ["task status", task.taskStatusUrl];
      const status = queryClient.getQueryData<TaskStatusPayload>(queryKey);

      if (status?.completed === true) {
        isCompleted = true;
      }
    }

    return { ...task, completed: isCompleted };
  });

  // displaying completed tasks last
  const sortedTasks = tasksWithStatus.sort((taskA, taskB) => {
    if (taskA.completed) {
      return 1;
    }

    if (taskB.completed) {
      return -1;
    }

    return 0;
  });

  return (
    <Grid container spacing={2} flexWrap="wrap">
      {sortedTasks.map((task) => (
        <Grid item xs={12} sm={6} key={task.taskName}>
          <AssignedTaskItem
            icon={allTaskList[task.taskName].icon}
            taskStatusUrl={task.taskStatusUrl || ""}
            description={allTaskList[task.taskName].description}
            taskRoute={allTaskList[task.taskName].taskRoute}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default AssignedTasksTile;
