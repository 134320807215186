/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from "react-query";
import { updateStartupFormProductionData } from "../../api/storyboard";
import { ProductionCardResponse, ProductionDetails } from "../../types/types";
import { noop } from "../../utils/appHelper";

type QueryContext = { previousCardDetails: ProductionCardResponse } | undefined;

interface UpdateStartupProductionDetailsArgs {
  ccid: string;
  formData: ProductionDetails;
}

export const useUpdateStartupProductionDetails = (
  onSuccess = noop,
  onError: (error: any, variables: any, context: QueryContext) => void = noop,
  onMutate?: (variables: any) => Promise<QueryContext>,
) => {
  return useMutation(
    ({ ccid, formData }: UpdateStartupProductionDetailsArgs) =>
      updateStartupFormProductionData({ ccid, formData }),
    {
      onSuccess,
      onError,
      onMutate,
    },
  );
};
