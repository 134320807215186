import { UseQueryResult, useQuery } from "react-query";
import { AxiosError } from "axios";
import { getTreatments } from "../../api/portal";
import { Treatments } from "../../types/types";

export const useGetTreatments = (
  ccid: string | undefined,
): UseQueryResult<Treatments, AxiosError> => {
  return useQuery({
    enabled: Boolean(ccid),
    queryKey: ["treatments", ccid],
    queryFn: () => getTreatments(ccid),
  });
};
