import ReactGA from "react-ga4";
import { useEffect } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { useLocation } from "react-router";

// ReactGA.initialize("UA-83724194-3");
ReactGA.initialize("G-J6M1DC2TCQ");

export const DROPDOWN_TO_A_BRAND = "dropdown to a brand";
export const SHORTCUT_TO_A_BRAND = "shortcut to a brand";
export const FULL_SEARCH_TO_A_BRAND = "full search to a brand";

export const trackClickEvent = (event: string): void => {
  const clickEvent = {
    category: "User",
    action: `user clicked ${event}`,
  };
  ReactGA.event(clickEvent);
};

export const trackErrorEvent = (statusCode: string, endpoint: string): void => {
  const errorEvent = {
    category: "Error",
    action: `${statusCode} on ${endpoint}`,
  };
  ReactGA.event(errorEvent);
};

const trackPage = (page: string) => {
  // ReactGA.set({ page });
  // ReactGA.pageview(page);
  ReactGA.send({ hitType: "pageview", page });
};

const useGA = (): void => {
  const location = useLocation();
  const { pathname, search } = location;

  useEffect(() => {
    trackPage(pathname + search);
  }, [pathname, search]);
};

export default useGA;
