import { ListItemIcon } from "@mui/material";
import { useTaskStatus } from "../hooks/queries";
import { CheckCircleOutlined } from "@mui/icons-material";

const TaskTitleIcon = ({
  icon,
  taskStatusUrl,
}: {
  icon: JSX.Element;
  taskStatusUrl: string;
}): JSX.Element => {
  const { data: taskStatus } = useTaskStatus(taskStatusUrl);
  return taskStatus?.completed ? (
    <ListItemIcon>
      <CheckCircleOutlined sx={{ fontSize: "40px" }} color="success" />
    </ListItemIcon>
  ) : (
    <ListItemIcon>{icon}</ListItemIcon>
  );
};

export default TaskTitleIcon;
