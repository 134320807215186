import { FC } from "react";
import {
  Typography,
  Stack,
  Box,
  Paper,
  Container,
  Skeleton,
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
  InputLabel,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { enqueueSnackbar } from "notistack";
import { useQueryClient } from "react-query";
import {
  useBrandMetadata,
  useSeriesMetadata,
  useProductioniseSeries,
  useCcidDropdown,
  useSeries,
  useTitles,
} from "../../hooks";
import {
  ProductionisePayload,
  CreateCcidFormData,
  ProductionType,
} from "../../types/types";
import { CcidCreationFormFormik } from "../CreateCcid/CcidCreationFormFormik";
import { SnackbarDismiss } from "../../componentsV2/SnackBarDismiss";

const currentYear = new Date().getFullYear();

const registrationSchema = Yup.object().shape({
  productionType: Yup.string<ProductionType>().required(),
  productionLabels: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      }),
    )
    .min(1, "This is a required field"),
  commissioner: Yup.string().required("This is a required field"),
  whoIsThisFor: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string().when("$commissioner", ([commissioner], schema) => {
      if (commissioner === "ITV") {
        return Yup.string().required("This is a required field");
      }
      return schema;
    }),
  }),
  productionYear: Yup.string().required("This is a required field"),
  count: Yup.string().when("productionType", {
    is: (productionType: string) => productionType === "series",
    then: () =>
      Yup.string()
        .required("This is a required field")
        .matches(/^[0-9]+$/, "Must be only numerical digits"),
  }),
  intendedSlotLength: Yup.string()
    .required("This is a required field")
    .matches(/^[0-9]+$/, "Must be only numerical digits"),
  productionLead: Yup.object()
    .shape({
      id: Yup.string(),
      firstName: Yup.string(),
      lastName: Yup.string(),
      email: Yup.string(),
      role: Yup.string(),
    })
    .notRequired(),
});

export const UpdateSeries: FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { programmeCcid, seriesCcid } = useParams<string>();
  const { data: seriesData } = useSeries(programmeCcid, true);
  const { data: brandTitlesData } = useTitles(programmeCcid, "brands", true);
  const { data: brandMetaData, isLoading: brandLoading } = useBrandMetadata(
    programmeCcid,
    true,
  );

  const useSpecialRouting = seriesData && seriesData.seriesDetails.length === 0;

  const routingButtonText = useSpecialRouting
    ? "Click here to update existing Special"
    : "Click here to update existing Series";

  const { data: seriesMetaData } = useSeriesMetadata(seriesCcid);

  const { mutate: updateSeries, isLoading: isUpdatingSeries } =
    useProductioniseSeries(
      () => {
        queryClient.invalidateQueries("series");
        enqueueSnackbar(`Series updated`, { variant: "success" });
        navigate(`../`);
      },
      () => {
        enqueueSnackbar(
          `There was an error updating your series, please try again.`,
          { variant: "error", action: SnackbarDismiss },
        );
      },
    );

  const { data: dropdownOptions } = useCcidDropdown();

  const onSubmit = (values: CreateCcidFormData) => {
    if (values.productionType === "series") {
      const formattedSeriesData: ProductionisePayload = {
        productionLabels: values.productionLabels
          ? Array.from(values.productionLabels, (p) => p.value)
          : [],
        commissioner: values.commissioner,
        count: Number(values.count),
        whoIsThisFor:
          values.commissioner === "ITV" ? values.whoIsThisFor.value : "OFF_ITV",
        productionYear: Number(values.productionYear),
        intendedSlotLength: Number(values.intendedSlotLength),
        productionLead: values.productionLead
          ? {
              name: `${values.productionLead.firstName} ${values.productionLead.lastName}`,
              email: values.productionLead.email,
            }
          : undefined,
      };
      if (programmeCcid !== undefined && seriesCcid !== undefined) {
        updateSeries({ ccid: seriesCcid || "", formData: formattedSeriesData });
      }
    }
  };

  return (
    <Container>
      <Paper>
        <Box my={2} p={2}>
          <Box mb={1}>
            <Typography variant="h4" display="inline">
              Update Series:
            </Typography>
            {brandLoading ? (
              <Skeleton sx={{ display: "inline-block" }}>
                <Typography variant="h4">Loading brand</Typography>
              </Skeleton>
            ) : (
              <Typography variant="h4" display="inline">
                {` ${brandMetaData?.brandName}`}
              </Typography>
            )}
          </Box>

          <Stack direction="row" spacing={4} my={4}>
            <Box my={2}>
              <Typography>
                We need some more information before we can update this series.{" "}
              </Typography>
            </Box>
          </Stack>
          {!seriesMetaData && seriesData && seriesData.seriesDetails ? (
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                if (useSpecialRouting && brandTitlesData && brandTitlesData.titleDetails.length > 0)
                  navigate(
                    `../../special/${brandTitlesData.titleDetails[0].ccid}/update-ccid`,
                  );
                else navigate(
                  `../series/${seriesData.seriesDetails[0].ccid}/update-series`,
                );
              }}
            >
              {routingButtonText}{" "}
              {!useSpecialRouting
                ? seriesData.seriesDetails.reverse()[0].seriesNumber
                : ""}
            </Button>
          ) : (
            <></>
          )}
          {dropdownOptions?.producers && seriesMetaData ? (
            <Formik
              initialValues={{
                productionType: "series",
                productionLabels: seriesMetaData.productionLabels
                  ? dropdownOptions.producers.filter((producer) =>
                      seriesMetaData.productionLabels.includes(producer.label),
                    )
                  : [],
                commissioner: "",
                whoIsThisFor: { label: "", value: "" },
                productionYear:
                  seriesMetaData.productionYearSpan?.earliest?.toString() ||
                  currentYear.toString(),
                specialName: "",
                count:
                  seriesMetaData.episodeCount === 0
                    ? ""
                    : seriesMetaData.episodeCount.toString(),
                intendedSlotLength:
                  seriesMetaData.intendedSlotLength?.toString() || "",
                genre: { label: "", value: "" },
                subgenres: [],
              }}
              validationSchema={registrationSchema}
              onSubmit={onSubmit}
            >
              {({ submitForm }) =>
                isUpdatingSeries ? (
                  <Box sx={{ display: "flex", justifyContent: "center", m: 2 }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Form>
                    <Stack spacing={1} my={2}>
                      <InputLabel>Series Number</InputLabel>
                      {seriesMetaData ? (
                        <TextField
                          name="sequence"
                          size="small"
                          sx={{ width: "50%" }}
                          autoComplete="off"
                          disabled
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Typography color="disabled">
                                  {seriesMetaData?.seriesNumber}
                                </Typography>
                              </InputAdornment>
                            ),
                          }}
                        />
                      ) : (
                        <Skeleton sx={{ display: "inline-block" }}>
                          <Typography variant="h4">
                            Loading Series Number
                          </Typography>
                        </Skeleton>
                      )}
                      <Alert severity="warning" sx={{ padding: 1 }}>
                        <AlertTitle>
                          <Typography variant="h6"> Requires Update</Typography>
                        </AlertTitle>
                        This series was created by a commissioner and exists in
                        the catalogue. Some information is missing from this
                        series, please provide some more information before we
                        can update this series.
                      </Alert>
                    </Stack>

                    <>
                      <Box my={2}>
                        <Typography>
                          We only need a few more details to update this series
                        </Typography>
                      </Box>

                      {seriesMetaData ? (
                        <CcidCreationFormFormik
                          useUpdateSeries
                          prepopulatedData={{
                            genre: seriesMetaData?.genre,
                            subGenre: seriesMetaData?.subgenres,
                            productionLabels: seriesMetaData?.productionLabels,
                            productionYear:
                              seriesMetaData?.productionYearSpan?.earliest,
                            episodeCount: seriesMetaData?.episodeCount,
                            intendedSlotLength:
                              seriesMetaData?.intendedSlotLength,
                            whoIsThisFor:
                              seriesMetaData?.originatingRequestArea,
                          }}
                        />
                      ) : (
                        <></>
                      )}

                      <br />
                      <Stack
                        direction="row"
                        justifyContent="center"
                        spacing={4}
                      >
                        <Button onClick={() => navigate("..")}>Cancel</Button>
                        <Button
                          variant="contained"
                          disabled={isUpdatingSeries}
                          onClick={submitForm}
                        >
                          Submit
                        </Button>
                      </Stack>
                    </>
                  </Form>
                )
              }
            </Formik>
          ) : (
            <></>
          )}
        </Box>
      </Paper>
    </Container>
  );
};
