import { useQuery, UseQueryResult } from "react-query";
import { AxiosError } from "axios";
import { getCloseDownFormData } from "../../api/storyboard";
import { CloseDownForm } from "../../types/types";

export const useCloseDown = ({
  ccid,
  type,
}: {
  ccid: string | undefined;
  type: "series" | "titles";
}): UseQueryResult<CloseDownForm, AxiosError> =>
  useQuery(
    ["closeDown", type, ccid],
    () => getCloseDownFormData({ ccid: ccid as string, type }),
    { enabled: Boolean(ccid) },
  );
