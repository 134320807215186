import { useGetUserProfile } from ".";

export function useUserFullName(): string | null {
  const { data: profile } = useGetUserProfile();

  if (!profile) {
    return null;
  }

  if (profile.viewingAs && profile.viewingAs.length > 0) {
    return profile.viewingAs;
  }

  return `${profile.firstName} ${profile.surname}`;
}
