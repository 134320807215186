import { useParams } from "react-router-dom";
import { Container, Grid, Skeleton, Stack, Typography } from "@mui/material";
import { useTitleTasks } from "../../hooks";
import TasksSection from "../TasksSection";
import AssignedTasksTile from "../AssignedTaskTile";
import { AuxActionsTile } from "../AuxActionsTile";
import { TaskListTile } from "../TaskListTile";
import { TreatmentTile } from "../SeriesTreatmentTile";

const SpecialTasksPage = (): JSX.Element => {
  const { titleCcid } = useParams();
  const { data, isLoading } = useTitleTasks(titleCcid);

  if (data?.tasks === undefined && !isLoading)
    return <Typography>There are no tasks to complete</Typography>;
  return (
    <Container sx={{ py: 4 }}>
      <Stack spacing={2}>
        <Typography variant="h5">Special Title Tasks</Typography>
        {isLoading || data?.tasks === undefined ? (
          <Skeleton height={298} variant="rounded" animation="wave" />
        ) : (
          <TasksSection tasks={data.tasks} productionStage="preProduction" />
        )}
        {isLoading || data?.tasks === undefined ? (
          <Skeleton height={298} variant="rounded" animation="wave" />
        ) : (
          <TasksSection tasks={data.tasks} productionStage="inProduction" />
        )}
        {isLoading || data?.tasks === undefined ? (
          <Skeleton height={298} variant="rounded" animation="wave" />
        ) : (
          <TasksSection tasks={data.tasks} productionStage="postProduction" />
        )}
      </Stack>
    </Container>
  );
};

export default SpecialTasksPage;

export function SpecialTasksPageNew() {
  const { titleCcid } = useParams();
  return (
    <Container sx={{ py: 4 }}>
      <Grid container direction="row" spacing="16px">
        <Grid item xs={12} md={6} sx={{ order: { xs: 2, md: 1 } }}>
          <Stack direction="column" spacing="32px">
            <AssignedTasksTile />
            <TreatmentTile ccid={titleCcid} level="titles" />
            <AuxActionsTile ccid={titleCcid} />
          </Stack>
        </Grid>

        <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 2 } }}>
          <TaskListTile ccid={titleCcid} />
        </Grid>
      </Grid>
    </Container>
  );
}
