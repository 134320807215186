/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery, UseQueryResult } from "react-query";
import { AxiosError } from "axios";
import { getAssignedTasks } from "../../api/portal";
import { TasksResponsePayload } from "../../types/types";

export const useGetAssignedTasks = (
  ccid: string,
): UseQueryResult<TasksResponsePayload, AxiosError> =>
  useQuery(["assignedTasks", ccid], () => getAssignedTasks(ccid as string), {
    enabled: Boolean(ccid),
  });
