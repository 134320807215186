import { useQuery } from "react-query";
import { getTasksForCcid } from "../../api/portal";

export function useTasksNew(ccid: string | undefined) {
  return useQuery({
    enabled: Boolean(ccid),
    queryKey: ["tasks", ccid],
    queryFn: () => {
      return getTasksForCcid(ccid as string);
    },
  });
}
