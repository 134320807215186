/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, UseMutationResult } from "react-query";
import { noop } from "../../utils/appHelper";
import { deleteIconikUsers } from "../../api/iconik";

export const useRemoveUsersFromIconik = (
  onSuccess = noop,
  onError = noop,
): UseMutationResult =>
  useMutation(
    (payload: any) =>
      deleteIconikUsers(payload.ccid, payload.groupId, payload.userEmails),
    {
      onSuccess,
      onError,
    },
  );
