/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { oktaAuthClient } from "../utils/oktaAuthClient";
import { trackErrorEvent } from "./useGA";

const getRequestConfig = (config?: AxiosRequestConfig) => {
  const basicConfig = {
    headers: {
      Authorization: `Bearer ${oktaAuthClient.getAccessToken()}`,
    },
  };

  if (config) {
    return {
      ...basicConfig,
      ...config,
    };
  }

  return basicConfig;
};

const handleRequest = {
  get: async (
    endpoint: string,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse> => {
    try {
      return await axios.get(endpoint, {
        ...getRequestConfig(config),
        paramsSerializer: { indexes: null },
      });
    } catch (error: any) {
      trackErrorEvent(error.response.status, endpoint);
      throw error;
    }
  },

  post: async (
    endpoint: string,
    body: unknown,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse> => {
    try {
      return await axios.post(endpoint, body, {
        ...getRequestConfig(config),
        paramsSerializer: { indexes: null },
      });
    } catch (error: any) {
      trackErrorEvent(error.response.status, endpoint);
      throw error;
    }
  },

  put: async <T>(
    endpoint: string,
    body?: T,
    config?: AxiosRequestConfig,
    axiosInstance?: AxiosInstance,
  ): Promise<AxiosResponse> => {
    try {
      if (axiosInstance) {
        return await axiosInstance.put(endpoint, body, {
          ...getRequestConfig(config),
          paramsSerializer: { indexes: null },
        });
      } else {
        return await axios.put(endpoint, body, {
          ...getRequestConfig(config),
          paramsSerializer: { indexes: null },
        });
      }
    } catch (error: any) {
      trackErrorEvent(error.response.status, endpoint);
      throw error;
    }
  },

  delete: async <T>(
    endpoint: string,
    data?: T,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse> => {
    try {
      return await axios.delete(endpoint, {
        ...getRequestConfig(config),
        paramsSerializer: { indexes: null },
        data,
      });
    } catch (error: any) {
      trackErrorEvent(error.response.status, endpoint);
      throw error;
    }
  },

  patch: async <T>(
    endpoint: string,
    body: T,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse> => {
    try {
      return await axios.patch(endpoint, body, {
        ...getRequestConfig(config),
        paramsSerializer: { indexes: null },
      });
    } catch (error: any) {
      trackErrorEvent(error.response.status, endpoint);
      throw error;
    }
  },
};

export default handleRequest;
