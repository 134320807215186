import { useOktaAuth } from "@okta/okta-react";
import { useQuery } from "react-query";

export function useCurrentOktaUser() {
  const { oktaAuth } = useOktaAuth();

  return useQuery({
    queryKey: "loggedInUser",
    queryFn: () => {
      return oktaAuth.getUser();
    },
  });
}
