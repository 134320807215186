/* eslint-disable @typescript-eslint/no-loop-func */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Formik, Form, FormikValues, FormikErrors } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  CircularProgress,
  Button,
  Dialog,
  CardContent,
  Card,
  Alert,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import cloneDeep from "lodash.clonedeep";
import { enqueueSnackbar } from "notistack";
import ReactToPrint from "react-to-print";
import {
  FormHeader,
  SideNav,
  StyledSectionTitle,
} from "../../../../components";
import {
  sanitizeFormData,
  getLatestDate,
  getStepsItemsNoRisk,
} from "../../../NotificationForm/notificationForm.helper";
import { basicFormConfig } from "./configs/basicInfo";
import { riskAndInsuranceFormConfig } from "./configs/riskAndInsurance";
import { businessFormConfig } from "./configs/startUpBusiness";
import { policiesFormConfig } from "./configs/startUpPolicies";
import { contributorsAndTalentFormConfig } from "./configs/contributorsAndTalent";
import { technicalProductionPlanFormConfig } from "./configs/technicalProductionPlan";
import { ModalWrapper } from "../../../../components/ModalWrapper";
import {
  useStartup,
  useUpdateStartup,
  useProductionDetails,
  useUpdateStartupProductionDetails,
  useMetadataDropdownNew,
} from "../../../../hooks";
import { TITLES, SERIES } from "../../../../constants";
import { NoAccess } from "../../../../components/NoAccess";
import theme from "../../../../utils/theme";
import { BasicInfoForm } from "./BasicInfoForm";
import { RiskAndInsuranceForm } from "./RiskAndInsuranceForm";
import { BuisnessForm } from "./BusinessForm";
import { PoliciesAndSustainabilityForm } from "./PoliciesAndSustainabilityForm";
import { ContributorsAndTalentForm } from "./ContributorsAndTalentForm";
import { TechnicalProductionPlanForm } from "./TechnicalProductionPlanForm";
import { AttendeesForm } from "./AttendeesForm";
import { SnackbarDismiss } from "../../../../componentsV2/SnackBarDismiss";

const attendee = {
  label: "Attendees",
  id: "attendee",
  formConfig: [
    {
      formFields: [
        // Only needed to get the side nav tick to work, see notificationForm.helper.ts: getStepsItemsNoRisk
        {
          id: "attendees",
          required: true,
        },
      ],
    },
  ],
};

const basic: any = {
  label: "Basic Info",
  id: "basicInfo",
  formConfig: basicFormConfig,
  description: (
    <div>
      This form brings all elements of risk together in one form, helping us
      review, manage, escalate and report on risk across key areas of risk.
      <p>
        You should get the form completed as soon as possible - ideally before
        the start-up meeting - to help us ensure you have the right support to
        manage potential risks. All the information you provide in the form will
        help us maintain a log of all current and ongoing production.
      </p>
      We are constantly updating and improving this form, so if you have any
      questions or feedback - please let us know by emailing risk@itv.com.
    </div>
  ),
};

const risk = {
  label: "Risk and Insurance",
  id: "riskAndInsurance",
  formConfig: riskAndInsuranceFormConfig,
};
const business = {
  label: "Business affairs",
  id: "business",
  formConfig: businessFormConfig,
};

const policies = {
  label: "Policies & Sustainability",
  id: "policiesAndSustainability",
  formConfig: policiesFormConfig,
};

const contributorsAndTalent = {
  label: "Contributors & Talent",
  id: "contributorsAndTalent",
  formConfig: contributorsAndTalentFormConfig,
};

const technicalProduction = {
  label: "Technical production plan",
  id: "technicalProductionPlan",
  formConfig: technicalProductionPlanFormConfig,
};

const intialFormikFormData = {
  attendee: {
    attendees: [],
  },
  basicInfo: {
    productionStatus: "",
    productionType: "",
    otherProductionType: "",
    broadcaster: "",
    otherBroadcaster: "",
    contentType: "",
    recordingType: "",
    outsideLocation: "",
    productionLabel: "",
    productionContactName: "",
    productionContactEmail: "",
    creativeLeadName: "",
    creativeLeadEmail: "",
  },
  riskAndInsurance: {
    insurances: "",
    previousSeriesIssues: "",
    businessRisks: "",
    businessContinuityPlan: "",
    incidentAndCrisisManagement: "",
    workingTimeIssues: "",
    smartWorking: "",
    healthAndSafety: "",
    covid19: "",
    additionalNotes: "",
  },
  business: {
    coFundingOrAdditionalIncome: "",
    costFormSubmissionDate: "",
    programmeTitleClearance: "",
    bribery: "",
    sanctions: "",
    format: "",
    releaseForms: "",
    contestants: "",
    rights: "",
    broadcastClearances: "",
    datesPerEpisode: false,
    seriesDeliveryDate: "",
    deliveryDates: "",
    additionalNotes: "",
  },
  contributorsAndTalent: {
    contributors: [],
    membersOfPublic: "",
    paidAdvertising: "",
    socialMediaSourcing: "",
    formatBackground: "",
    visaOrChildLicences: "",
    talentPayments: "",
    dutyOfCare: "",
    additionalNotes: "",
  },
  policiesAndSustainability: {
    policiesShared: false,
    producersHandbookShared: false,
    albert: false,
    carbonActionPlanStarted: false,
    sustainableInitiatives: "",
    greenTeamContact: "",
    privacyAndData: "",
    additionalNotes: "",
  },
  technicalProductionPlan: {
    deliveryFormat: "",
    broadcastClearances: "",
    versions: "",
    internationalVersionsRequired: false,
    productionBibleRequired: false,
    pressAndPublicity: "",
    interactiveInvolvement: "",
    prizesAndPrtsInfo: "",
    technologyWorkflow: "",
    mediaRetention: "",
    digital: "",
    compliance: "",
    music: "",
    procurement: "",
    additionalNotes: "",
  },
};

export const groupMenuSetting = [
  attendee,
  basic,
  risk,
  business,
  policies,
  contributorsAndTalent,
  technicalProduction,
];

export interface NotificationFormProps {
  formTitle?: string;
}

export const StartupFormV2: FC<NotificationFormProps> = ({
  formTitle = "Start Up form",
}) => {
  const { seriesCcid, titleCcid } = useParams();
  const type = titleCcid ? TITLES : SERIES;
  const ccid = titleCcid || seriesCcid;
  const initialGroupMenuSetting = groupMenuSetting;
  const {
    data: startupFormData,
    error: startupError,
    isLoading: loadingStartup,
  } = useStartup({ ccid, type });
  const { data: startupFormProductionData, isLoading: loadingProdDetails } =
    useProductionDetails(ccid);
  const { data: metaDataDropdown } = useMetadataDropdownNew();
  const [autoSaveAt, setAutoSaveAt] = useState<string>("");

  const basicInfoformConfig = useMemo(() => {
    // const form = type === "titles" ? titleBasicFormConfig : basicFormConfig;
    const copyConfig = cloneDeep(basicFormConfig);

    copyConfig.forEach((config: any) => {
      config.formFields.forEach((field: any) => {
        field.disabled = true;

        if (field.type === "DATE") {
          field.type = "TEXT";
        }
      });
    });

    return copyConfig;
  }, []);

  const setAutoSaveDate = useCallback((response: any) => {
    const latestDate = getLatestDate(response) || new Date().toISOString();
    setAutoSaveAt(latestDate);
  }, []);

  const cardRef = useRef<any>();
  const componentRef = useRef(null);
  type Tab =
    | "attendee"
    | "basicInfo"
    | "riskAndInsurance"
    | "business"
    | "policiesAndSustainability"
    | "contributorsAndTalent"
    | "technicalProductionPlan";
  const [currentTab, setCurrentTab] = useState<Tab>(groupMenuSetting[0].id);
  const [printModalOpen, setPrintModalOpen] = useState<boolean>(false);
  const [confirmationDialogOpen, SetConfirmationDialogOpen] =
    useState<boolean>(false);

  const [formData, setFormData] = useState<any>(intialFormikFormData);
  const [step, setStep] = useState<number>(1);

  useEffect(() => {
    setStep(groupMenuSetting.findIndex(({ id }) => id === currentTab) + 1);
  }, [currentTab]);

  const deepMerge = useCallback(
    (obj1: { [x: string]: any }, obj2: { [x: string]: any }) => {
      const result = { ...obj1 };
      for (const key in obj2) {
        if (Object.prototype.hasOwnProperty.call(obj2, key)) {
          if (typeof obj2[key] === "object" && obj1[key]) {
            result[key] = deepMerge(obj1[key], obj2[key]);
          } else {
            result[key] = obj2[key];
          }
        }
      }
      return result;
    },
    [],
  );

  const formatCamelCase = (s: string) =>
    s.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase());

  const { mutate: updateStartUp } = useUpdateStartup(
    (response) => {
      setAutoSaveDate(response);
      enqueueSnackbar("Content Autosaved", { variant: "success" });
    },
    () => {
      enqueueSnackbar("There is an error on saving the form data", {
        variant: "error",
        action: SnackbarDismiss,
        persist: true,
      });
    },
  );

  const { mutate: updateProdFormData } = useUpdateStartupProductionDetails(
    (response) => {
      setAutoSaveDate(response);
      enqueueSnackbar("Content Autosaved", { variant: "success" });
    },
    () => {
      enqueueSnackbar("There is an error on saving the form data", {
        variant: "error",
        action: SnackbarDismiss,
        persist: true,
      });
    },
  );

  useEffect(() => {
    if (startupFormData && startupFormProductionData) {
      // Refine the Production details data
      const basicInfoProdData = sanitizeFormData(
        startupFormProductionData,
        [{ formConfig: basicInfoformConfig, id: "basicInfo" }],
        true,
      );
      // Isolate the coreCat data that is not editable
      const coreCatdata = basicInfoProdData?.basicInfo.coreCatalogueData;
      // Merge this into the main object so it can be unpacked by the component
      Object.assign(basicInfoProdData, coreCatdata);
      // Remove the excess data embdedded in the object
      delete basicInfoProdData.basicInfo;
      // Set up the main start up for data
      const mainStartUpFormData = sanitizeFormData(
        startupFormData,
        initialGroupMenuSetting,
        true,
      );
      const startUpWithBasicInfo = mainStartUpFormData;
      // Merge the main and the basic info into one set to be displayed by the component(s)
      startUpWithBasicInfo.basicInfo = basicInfoProdData;
      if (startUpWithBasicInfo.business === undefined) {
        startUpWithBasicInfo.business = { datesPerEpisode: false };
      } else if (startUpWithBasicInfo.business.datesPerEpisode === undefined) {
        startUpWithBasicInfo.business.datesPerEpisode = false;
      }

      /* TODO: Can we do something better here? */
      startUpWithBasicInfo.basicInfo.outsideLocation = String(
        startUpWithBasicInfo.basicInfo.outsideLocation,
      );

      setFormData((prevFormData: { [key: string]: any }) =>
        deepMerge(prevFormData, startUpWithBasicInfo),
      );
    }
  }, [
    initialGroupMenuSetting,
    startupFormData,
    basicInfoformConfig,
    startupFormProductionData,
    deepMerge,
  ]);

  const blurHandler = useCallback(
    (id: string, newValue: any) => {
      // code to save update in the backend.

      if (currentTab === "basicInfo") {
        const newId = id.split(".")[1];
        updateProdFormData({
          ccid: ccid as string,
          formData: { [newId]: newValue },
        });
      } else {
        updateStartUp({
          ccid: ccid as string,
          formData: { [id.split(".")[0]]: { [id.split(".")[1]]: newValue } },
          type,
        });
      }
    },
    [currentTab, ccid, updateStartUp, type, updateProdFormData],
  );

  const validationSchema = Yup.object().shape({
    basicInfo: Yup.object().shape({
      productionStatus: Yup.string().required("Required"),
      productionType: Yup.string().required("Required"),
      otherProductionType: Yup.string().required("Required"),
      broadcaster: Yup.string(),
      otherBroadcaster: Yup.string().required("Required"),
      contentType: Yup.string().required("Required"),
      recordingType: Yup.string(),
      outsideLocation: Yup.boolean().required("Required"),
      productionLabel: Yup.string(),
      productionContactName: Yup.string().required("Required"),
      productionContactEmail: Yup.string()
        .email("Invalid email")
        .required("Required"),
      creativeLeadName: Yup.string().required("Required"),
      creativeLeadEmail: Yup.string()
        .email("Invalid email")
        .required("Required"),
    }),
    riskAndInsurances: Yup.object().shape({
      insurances: Yup.string(),
      previousSeriesIssues: Yup.string(),
      businessRisks: Yup.string(),
      businessContinuityPlan: Yup.string(),
      incidentAndCrisisManagement: Yup.string(),
      workingTimeIssues: Yup.string(),
      smartWorking: Yup.string(),
      healthAndSafety: Yup.string(),
      covid19: Yup.string(),
      additionalNotes: Yup.string(),
      // Add other fields here
    }),
  });

  const tabFields = (
    values: FormikValues,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => Promise<void | FormikErrors<any>>,
  ): Record<Tab, JSX.Element> => ({
    attendee: (
      <AttendeesForm
        attendees={Object.values(values.attendee.attendees)}
        setFieldValue={setFieldValue}
        blurHandler={blurHandler}
      />
    ),
    basicInfo: (
      <BasicInfoForm
        values={values}
        metaDataDropdown={metaDataDropdown}
        blurHandler={blurHandler}
      />
    ),
    riskAndInsurance: (
      <RiskAndInsuranceForm
        values={values}
        blurHandler={blurHandler}
        setFieldValue={setFieldValue}
      />
    ),
    business: (
      <BuisnessForm
        values={values}
        setFieldValue={setFieldValue}
        blurHandler={blurHandler}
      />
    ),
    policiesAndSustainability: (
      <PoliciesAndSustainabilityForm
        values={values}
        blurHandler={blurHandler}
        setFieldValue={setFieldValue}
      />
    ),
    contributorsAndTalent: (
      <ContributorsAndTalentForm
        values={values}
        blurHandler={blurHandler}
        setFieldValue={setFieldValue}
      />
    ),
    technicalProductionPlan: (
      <TechnicalProductionPlanForm
        values={values}
        blurHandler={blurHandler}
        setFieldValue={setFieldValue}
      />
    ),
  });

  const submitHandler = () => {
    SetConfirmationDialogOpen(true);
  };

  if (startupError) return <NoAccess />;

  return (
    <>
      <FormHeader formTitle={formTitle} showFormStatus />
      {loadingStartup || loadingProdDetails ? (
        <Stack alignItems="center" m={4}>
          <CircularProgress />
        </Stack>
      ) : (
        <Stack ref={cardRef}>
          <Dialog open={confirmationDialogOpen}>
            <Stack direction="column" spacing={2} sx={{ m: 2 }}>
              <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                Your answers have been saved
              </Alert>
              <Button
                variant="contained"
                color="primary"
                onClick={() => SetConfirmationDialogOpen(false)}
              >
                Close
              </Button>
            </Stack>
          </Dialog>
          <Formik
            initialValues={formData}
            validationSchema={validationSchema}
            // eslint-disable-next-line
            onSubmit={() => {}}
            enableReinitialize
          >
            {({ values, isSubmitting, setFieldValue }) => (
              <Container fixed disableGutters>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <SideNav
                      formData={values}
                      isSubmitting={isSubmitting}
                      groupMenuSetting={groupMenuSetting}
                      onStepChange={(tab: string) => {
                        setCurrentTab(tab as unknown as Tab);
                      }}
                      autoSaveAt={autoSaveAt}
                      activeStep={currentTab}
                      onSubmitForm={submitHandler}
                      getStepsItems={getStepsItemsNoRisk}
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <Stack>
                      <Box paddingTop={1}>
                        <Stack
                          direction="row"
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="h5">
                            Section {step} of {groupMenuSetting.length}:{" "}
                            {groupMenuSetting[step - 1].label}{" "}
                          </Typography>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              setPrintModalOpen(true);
                            }}
                          >
                            Download PDF
                          </Button>
                        </Stack>
                        <Typography marginTop={2}>
                          Please fill out the following fields to the best of
                          your knowledge to prepare for the start up meeting
                        </Typography>
                        <Box marginTop="1.5rem">
                          <Form>
                            <Stack spacing={3}>
                              {tabFields(values, setFieldValue)[currentTab]}
                              <div ref={componentRef}>
                                {printModalOpen ? (
                                  Object.entries(
                                    tabFields(values, setFieldValue),
                                  ).map(([key, field]) => (
                                    <div
                                      key={key}
                                      style={{ pageBreakBefore: "always" }}
                                    >
                                      <Card>
                                        <CardContent sx={{ padding: "1rem" }}>
                                          <StyledSectionTitle>
                                            <Typography variant="h5">
                                              {formatCamelCase(key)}
                                            </Typography>
                                          </StyledSectionTitle>
                                          {field}
                                        </CardContent>
                                      </Card>
                                    </div>
                                  ))
                                ) : (
                                  <></>
                                )}
                              </div>
                            </Stack>
                          </Form>
                          <Typography fontStyle="italic" marginTop={2}>
                            Indicates a required field{" "}
                            <Typography
                              color={theme.palette.sunsetOrange}
                              display="inline-block"
                            >
                              *
                            </Typography>
                          </Typography>
                        </Box>
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
              </Container>
            )}
          </Formik>
        </Stack>
      )}
      <ModalWrapper />
      <Dialog open={printModalOpen}>
        <Stack direction="column" spacing={2} sx={{ m: 2 }}>
          <Typography>
            Please confirm that you would like to download the current form
            using the save to PDF print function (this may take a few seconds)
          </Typography>
          <Stack
            direction="row"
            spacing={2}
            sx={{ m: 2, justifyContent: "space-between", display: "flex" }}
          >
            <ReactToPrint
              trigger={() => (
                <Button variant="contained" color="primary">
                  Confirm Print to PDF
                </Button>
              )}
              content={() => componentRef.current}
            />
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setPrintModalOpen(false)}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
};
